.form-border input[type='text'],
.form-border textarea,
.form-underline input[type='email'],
.form-border input[type='password'],
.form-border input[type='date'],
.form-border select {
  margin-bottom: 0;
  font-weight: bold;
  border: none;
  background-color: transparent;
  background: none;
}

.id-color {
  color: #199e1c;
}

button.ant-btn.ant-btn-block.ant-btn-primary {
  color: white !important;
  background-color: #199e1c;
}

button.ant-btn-primary.ant-btn-dangerous {
  background-color: #ff4d4f !important;
  &.ant-btn-disabled {
    color: white !important;
  }
}

.ant-btn-primary.ant-btn-dangerous.ant-btn-disabled {
  color: white !important;
}

.form-border input[type='text']:focus,
.form-border textarea:focus,
.form-underline input[type='email']:focus,
.form-border input[type='date']:focus,
.form-border select:focus,
#quick_search:hover,
.items_filter .dropdown:hover {
  box-shadow: none;
}

.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 15px;
  right: 10px;
}

.ant-input-affix-wrapper {
  padding: 0;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-btn-primary {
  background-color: #199e1c;
}
.ant-btn-primary:not(:disabled):hover {
  background-color: #199e1c;
}

.ant-btn-default:not(:disabled):active,
.ant-btn-default:not(:disabled):hover,
.ant-input:hover {
  color: #199e1c;
  border-color: #199e1c;
}

.text-right {
  text-align: right !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 2px !important;
}

.ant-modal-confirm-confirm {
  margin-top: 10%;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #199e1c;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: #199e1c;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #199e1c;
}

.rti--input {
  width: 100%;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon {
  border-color: #199e1c;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #199e1c;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-title,
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-item-description {
  color: #199e1c;
}
