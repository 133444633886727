@import './about';
@import './admin';
body {
  line-height: 1.7em !important;
}
.banner-main-title {
  font-family: var(--title-font);
}
header {
  z-index: 1000 !important;
}
section#section {
  background-color: white;
}

a.small-device-nav-trigger {
  margin-top: -10px;
  font-size: 20px;
  color: white !important;
  display: none;
}

.shadow-soft {
  background-color: #fff;
}

.banner-main-title {
  font-size: 50px;
}

p {
  color: #727272;
}
h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 42px;
  letter-spacing: -1.8px;
  line-height: 50px !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

h5 {
  font-size: 16px;
  font-weight: bold;
}

#mainmenu li ul.mega a.btn-main,
.btn-main,
a.btn-line,
a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
input[type='button'].btn-main {
  padding: 10px 30px;
}

.nav-link .active {
  background-color: var(--primary-color);
}

.nav-link {
  color: var(--primary-color);
}

.de-item .d-price .btn-main {
  padding: 6px 18px;
}

.services-section .services p {
  color: #fff;
}

.post-image .post-title h4 a {
  text-decoration: none;
}

.text-light p {
  color: #fff;
}

#mainmenu {
  font-size: 18px;
}

#mainmenu li li a:hover {
  color: #199e1c;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1300px !important;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color) !important;
}

.nav-link {
  color: var(--primary-color) !important;
}

.de-box .s1 a {
  text-decoration: none;
  color: var(--primary-color) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}

.contact-section {
  h3 {
    margin-bottom: 20px;
    font-size: 26px;
    letter-spacing: -0.5px;
  }
  .field-set {
    input {
      // margin-bottom: 10px;
      font-size: 14px;
      font-weight: normal;
    }
  }
}
#logo {
  a {
    display: block;
    padding: 5px 0;
  }
}
.de_checkbox {
  padding-right: 20px;
}
.drawer-navigation {
  list-style: none;
  padding-left: 10px;
  li {
    i {
      display: inline-block;
      width: 30px;
    }
    a {
      display: block;
      font-size: 18px;
      display: block;
      padding: 8px 0;
      color: #555;
      &.active {
        color: #199e1c;
        font-weight: bold;
      }
    }
  }
}
.ant-drawer-body {
  padding-top: 10px !important;
}
.primary-image-details {
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.pr-0 {
  padding-right: 0 !important;
}
@media all and (max-width: 768px) {
  a.small-device-nav-trigger {
    display: block;
  }
  .mainmenu-nav {
    display: none;
    margin: 0;
  }
  div#logo {
    padding-bottom: 15px;
  }
  .topbar-widget {
    font-size: 13px;
    padding: 3px 6px;
  }
}
@media all and (max-width: 450px) {
  a.small-device-nav-trigger {
    display: block;
  }
  .banner-main-title {
    font-size: 36px;
  }
  .vertical-center {
    min-height: 65vh !important;
  }
  header div#logo {
    img {
      margin-bottom: 15px;
    }
  }
  .car-container {
    margin-top: 60px !important;
  }

  .services-title {
    h1 {
      text-align: center;
      font-size: 38px;
    }
  }
  .services-section {
    .services {
      text-align: center;
    }
  }
  .contact-title {
    font-size: 29px;
    text-align: center;
  }

  .contact-content {
    text-align: center;
    margin-top: 20px;
  }

  .pnb-num {
    font-size: 32px;
  }

  .contact-title {
    font-size: 36px;
    text-align: center;
  }

  .contact-content {
    text-align: center;
    margin-top: 20px;
  }

  .pnb-num {
    font-size: 32px;
  }
  .subfooter {
    .de-flex-col {
      a {
        font-size: 14px;
      }
    }
  }
  .mainmenu-nav {
    display: none;
    margin: 0;
  }
}

@media all and (max-width: 800px) {
  .subfooter {
    text-align: center;
  }
}

.menu-col li a {
  text-decoration: none !important;
}
.ant-input-affix-wrapper {
  padding: 0;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
input::-moz-selection {
  background-color: transparent;
  color: inherit;
}

input::selection {
  background-color: transparent;
  color: inherit;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 7px 11px !important;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-start: 10px;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  background-color: #199e1c;
}

button:focus {
  outline: none;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #199e1c;
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  background-color: #199e1c;
  opacity: 0.6;
  color: white;
}
.fleet-image {
  height: 250px;
  object-fit: cover;
}

.de-item {
  .d-info {
    height: 270px;
    .d-atr-group {
      height: 125px;
    }
  }
}

.feature-icon {
  right: 300px;
}

// .box-icon.s2.d-invert i {
//   right: 300px;
// }

.image-placeholder {
  height: 250px;
  background-color: #eee;
}

.seen_message {
  opacity: 0.5;
}

.ant-steps-item-description {
  font-size: 13px !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #199e1c;
  border-color: #199e1c;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #199e1c;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #d0f4d1;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #199e1c;
}
.ant-picker-focused {
  border-color: #199e1c;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #199e1c;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background: #199e1c;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: #199e1c;
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #d0f4d1;
}

.next-icon-svg {
  svg {
    vertical-align: -2px !important;
    font-size: 18px;
  }
}

.map-container {
  // height: 580px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  .map-display {
    flex: 1;
    min-height: 300px;
  }
  .map-info-display {
    height: 90px;
    width: 100%;
    display: flex;
    border: 1px solid #ccc;
    border-top: none;
    .map-content-distance {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-right: 1px solid #ccc;
    }
    .map-content-timer {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.step-summary {
  background-color: #f6f6f6;
  padding: 20px;
  .summary {
    small {
      color: #777;
      font-weight: lighter;
      font-size: 13px;
      text-transform: uppercase;
    }
    p {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.step-1-summary {
  .summary {
    &:last-child {
      border-bottom: none;
    }
    border-bottom: 0.05em solid #c3c3c3;
    margin-bottom: 20px;
  }
}

.fleet-filter {
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.fleet-category-page {
  text-align: center;
  margin: 20px 0;
  li {
    display: inline;
    font-size: 20px;
    font-weight: bold;
    color: #333;
    a {
      color: #333;
    }
    a.active {
      color: #199e1c;
      text-decoration: underline;
    }
  }
}

.service-top {
  margin-top: 155px;
}

.section-content {
  margin-top: 150px;
}
.reservation-steps {
  img,
  svg {
    vertical-align: baseline;
  }
}

.fleet-list-view {
  .reservation-container {
    margin-top: 30px;
    margin-bottom: 40px;
    .reservation-car-section {
      padding: 40px 20px;
      border-bottom: 2px solid #eaecee;
      min-height: 250px;
      &:last-child {
        border-bottom: none;
      }
      .reservation-image-section {
        img {
          width: 100%;
        }
      }
      .reservation-car-details {
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        h4 {
          font-size: 22px;
          font-weight: 500 !important;
        }
        span {
          color: #199e1c;
          font-weight: bold;
          font-size: 40px;
        }
        p {
          color: #778591;
        }
      }

      .reservation-button-section {
        .reservation-button {
          text-align: right;
          svg {
            vertical-align: baseline;
          }
          button {
            &.active {
              background-color: #199e1c;
              color: white;
            }
            padding: 16px 32px;
            border: none;
            border-radius: 40px;
          }
        }
        .reservation-icons {
          display: flex;
          justify-content: space-around;
          margin-top: 70px;
          i {
            font-size: 50px;
            color: #ced3d9;
          }
          .fa-circle {
            font-size: 30px;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .extra-options {
    h2 {
      font-weight: 300 !important;
      font-size: 34px;
      margin-bottom: 40px;
    }
    .reservation-extra-container {
      .reservation-details {
        .extra-details {
          padding: 24px 16px 24px 0px;
          border-top: 2px solid #eaecee !important;
          border-right: 2px solid #eaecee !important;

          h4 {
            font-size: 22px;
            font-weight: 400 !important;
            span {
              color: #d2ab67;
              padding-left: 20px;
            }
          }
          p {
            color: #778591;
            font-weight: 200 !important;
            font-size: 18px;
            margin: 0px;
          }
        }
        .reservation-button {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: 2px solid #eaecee !important;
          border-left: 2px solid #eaecee !important;

          button {
            padding: 14px 32px 16px 32px;
            border: none;
            border-radius: 90px;
            background-color: #eaecee;
            color: #778591;
          }
        }
      }
    }
  }
}

.service-section {
  background-color: #fff !important;
  padding-top: 10px;
}

.de-box {
  .s1 {
    .footer-social-link {
      .social-icons {
        a {
          i {
            font-size: 20px;
            padding: 10px;
            margin-top: 4px;
            background-color: #199e1c !important;
          }
        }
      }
    }
  }
}

.footer-social-link {
  .social-icons {
    a {
      margin: 0px 4px;

      i {
        font-size: 20px;
        padding: 10px;
        margin-top: 4px;
        background-color: #199e1c !important;
        color: #fff;
        margin: 0px 4px;
      }
    }
  }
}

.widget {
  margin-bottom: 0px;
  .service-detail-icon {
    .social-icons {
      margin-top: 20px;
      a {
        i {
          background-color: var(--secondary-color);
          color: #fff;
        }
      }
    }
  }
}

.ant-alert-info {
  .ant-alert-message {
    font-weight: bold;
  }
  background-color: rgba(23, 158, 61, 0.1);
  border: 1px solid #199e1c;
}

.ant-steps-icon {
  svg {
    vertical-align: initial !important;
  }
}

.nav-pills {
  .nav-link {
    margin: 0px 10px;
  }
  .nav-link {
    border: none;
  }
}

.section-car-details {
  margin-top: 130px;
  .item {
    img {
      object-fit: cover;
    }
    .car-section-image {
      // margin-top: 10px;
      margin-right: 10px;
    }
    .car-section-image-2 {
      margin-top: 10px;
    }
  }
  .car-section {
    margin-top: 40px;
  }
  .de-box {
    .fleet-details-title {
      margin-bottom: 5px !important;
      color: #199e1c;
      i {
        margin-right: 8px;
      }
    }
    .jls-address-preview {
      .jls-address-preview__header {
        p {
          margin-left: 32px;
        }
      }
    }
  }
  .de-color-icons {
    .car-detail-span-section {
      display: flex;

      h5 {
        line-height: 40px;
        margin-left: 10px;
      }
    }
  }
}

.service-rate {
  padding-top: 100px;
  padding-bottom: 20px;
  margin-top: 80px;

  .service-rates-contains {
    background-color: #edecec;

    table {
      thead {
        tr {
          th {
            text-align: center;
            background-color: #199e1c;
            color: #fff;
            padding: 10px 0;
            font-size: 16px;
          }
        }
      }

      tbody {
        border: 3px solid #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        tr {
          th {
            text-align: center;
            background-color: white;
            img {
              height: 100%;
            }
          }

          td {
            text-align: center;
            padding-top: 30px;
            border: 2px solid #fff;

            span {
              color: #199e1c;
              font-size: 18px;
              font-weight: 600;
            }

            p {
              font-size: 12px;
              color: #9c9c9c;
            }
          }
        }
      }
    }

    .success-page {
      text-align: center;
      background-color: #fff;

      .success-container {
        h1 {
          font-size: 24px;
          margin: 0;
        }

        p {
          font-weight: 500;
          font-size: 14px;
        }

        .transfer-details {
          border: 2px solid #abaaaa;
          border-radius: 10px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          padding: 15px;
          width: 100%;
          max-width: 800px;
          margin: auto;
          background-color: #f1f1f1;

          h4 {
            color: #696868;
            font-size: 18px;
            margin-top: 15px;
          }

          .transfer-container {
            .transfer-contents {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              padding: 8px 0;

              h5 {
                font-size: 16px;
                font-weight: 400;
              }
            }

            .transfer-button {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 15px;

              .save-button,
              button {
                margin-top: 10px;
                padding: 10px 20px;
                border-radius: 20px;
                font-size: 14px;
              }

              .save-button {
                background-color: #199e1c;
                border: none;
                color: #fff;
              }

              button {
                background-color: #fff;
                border: 2px solid #199e1c;
                color: #199e1c;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .service-rate {
      padding-top: 50px;
      padding-bottom: 10px;
    }

    .service-rates-contains {
      table {
        thead {
          tr {
            th {
              font-size: 14px;
              padding: 8px 0;
            }
          }
        }

        tbody {
          tr {
            td {
              span {
                font-size: 16px;
              }

              p {
                font-size: 10px;
              }
            }
          }
        }
      }

      .success-page {
        .success-container {
          h1 {
            font-size: 20px;
          }

          p {
            font-size: 12px;
          }

          .transfer-details {
            padding: 10px;
            h4 {
              font-size: 16px;
            }

            .transfer-container {
              .transfer-contents {
                h5 {
                  font-size: 14px;
                }
              }

              .transfer-button {
                .save-button,
                button {
                  padding: 8px 16px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.success-page {
  text-align: center;
  background-color: #fff;
  padding-top: 150px;
  padding-bottom: 50px;
  .thankyou-message {
    margin: auto;
    max-width: 800px;
  }
  .success-container {
    h1 {
      font-size: 30px;
      margin: 0px;
    }
    p {
      font-weight: 500;
    }
    .transfer-details {
      border: 2px solid #abaaaa;
      border-radius: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 20px;
      width: 800px;
      margin: auto;
      background-color: #f1f1f1;
      h4 {
        color: #696868;
      }
      .transfer-container {
        .transfer-contents {
          display: flex;
          justify-content: space-between;
          padding: 8px 0px;
          h5 {
            font-size: 18px;
            font-weight: 400 !important;
          }
        }
        .transfer-button {
          .save-button {
            margin-right: 20px;
            background-color: #199e1c;
            border: none !important;
            color: #fff !important;
            border-radius: 20px;
          }
          button {
            padding: 16px 20px;
            color: #000;
            border-radius: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .service-rate {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 425px) {
  .service-rate {
    .service-rates-contains {
      margin-bottom: 20px;
    }
    .all-devices-rate {
      display: none;
    }
  }
}

@media only screen and (min-width: 600px) {
  .service-rate {
    .medium-rates {
      display: none;
    }
  }
}

// @media only screen and (max-width: 800px) {
//   .service-rate {
//     .service-rates-contains {
//       margin-bottom: 20px;
//     }
//     .all-devices-rate {
//       display: none;
//     }
//   }
// }

@media only screen and (max-width: 800px) {
  .service-top {
    margin-top: 50px;
  }
}

.fleet-detail-image {
  min-height: 510px;
  .details-main-image {
    min-height: 410px;
    width: 100%;
    background-color: #eee;
    img {
      width: inherit;
      object-fit: cover;
    }
  }
  .image-icon-list {
    div.image-icon {
      display: inline-block;
      background-color: #eee;
      width: 135px;
      height: 95px;
      margin-right: 10px;
      margin-top: 10px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.ant-spin {
  color: #199e1c;
  .ant-spin-dot-item {
    background-color: #199e1c;
  }
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #199e1c;
  border-color: #199e1c;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  color: #199e1c;
  border-color: #199e1c;
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  border-color: #199e1c;
}

.btn-main.fullbtn {
  width: 100%;
}
.bg-default {
  background-color: white;
  color: #444;
}

.lg-backdrop {
  opacity: 0.7 !important;
}

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 100%;
}

.ant-steps .ant-steps-item-content {
  width: 350px;
}
.container-new {
  padding-left: 30px;
  padding-right: 30px;
}

.reservation-title {
  title {
    display: none;
  }
}

.ant-steps-item-content {
  display: none;
}

// .slick-track {
//   width: 100% !important;
// }

.slick-slide {
  padding: 0 15px;
}

.slick-list {
  margin: 0 2em;
}

.slick-dots li button:before {
  font-size: 17px;
}

.post-content {
  .post-image-services {
    img {
      object-fit: cover;
    }
  }
}

.de-post-type-1 {
  .d-image {
    img {
      object-fit: cover;
    }
  }
}

.menu-simple {
  display: flex;
  .powered-by {
    display: flex;
    .powered-by-class {
      padding-left: 15px;
    }
  }
}

.book-now-mooovs {
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
}
